/* eslint-disable no-undef */
import firebase from 'firebase/app';
import 'firebase/messaging';
import UserNotificationApi from './UserNotificationApi';
import AppConfig from 'constants/AppConfig';
import NotificationApi from './NotificationApi';

function iOS() {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

export const initializeFirebase = async (userId: string) => {
  var registration: ServiceWorkerRegistration;
  if ('Notification' in window && firebase.messaging.isSupported()) {
    console.log(`NODE_ENV: ${process.env.NODE_ENV}`);
    if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
      registration = await navigator.serviceWorker.register(
        `firebase-messaging-sw.js?apiKey=${AppConfig.firebase.apiKey}&projectId=${AppConfig.firebase.projectId}&messagingSenderId=${AppConfig.firebase.messagingSenderId}&appId=${AppConfig.firebase.appId}`
      );
      console.log(
        'Registration of service worker was successful, scope is:',
        registration.scope
      );
    }

    var firebaseConfig = {
      apiKey: AppConfig.firebase.apiKey,
      projectId: AppConfig.firebase.projectId,
      messagingSenderId: AppConfig.firebase.messagingSenderId,
      appId: AppConfig.firebase.appId,
    };
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    } else {
      firebase.app();
    }

    const messaging = firebase.messaging();
    if (!iOS()) {
      Notification.requestPermission()
        .then((permission) => {
          console.log('Notification permission:', permission);
          if (permission === 'granted') {
            messaging
              .getToken({
                serviceWorkerRegistration: registration,
              })
              .then((token) => {
                if (token) {
                  console.log('Firebase token generated:', token);
                  if (!isTokenEqual(token)) {
                    console.log('New firebase token, send it to the server...');
                    sendTokenToNotificationApi(token);
                    //This is here for compatibility with the foundation, REMOVE when possible!
                    sendTokenToMonolithic(token);
                  }
                } else {
                  console.warn('No registration token available');
                }
              })
              .catch((error) => {
                console.warn(`Error getting firebase token: ${error}`);
              });
          }
        })
        .catch((error) => {
          console.warn(`Error getting firebase permission: ${error}`);
        });
    }

    messaging.onMessage(function (payload) {
      console.log('Message received: ', payload);
      let notificationTitle: string;
      let notificationOptions: NotificationOptions;
      if (payload.data) {
        notificationTitle = payload.data.title;
        notificationOptions = {
          body: payload.data.body,
          icon: '/accuv-logo.png',
        };
      } else {
        notificationTitle = payload.notification.title;
        notificationOptions = {
          body: payload.notification.body,
          icon: '/accuv-logo.png',
        };
      }
      var notification = new Notification(notificationTitle, notificationOptions);

      if (payload && payload.data && payload.data.title === 'Incoming video call') {
        var w = window.outerWidth * 0.93;
        var h = window.outerHeight * 0.85;
        var leftPosition = window.outerWidth / 2 - w / 2;
        var topPosition = window.outerHeight / 2 - h / 2;
        const now = new Date();
        var params = window.btoa(
          encodeURIComponent(
            ''.concat(
              'env=',
              payload.data.env,
              '&type=incoming&userid=',
              payload.data.userId,
              '&username=',
              payload.data.userName,
              '&callerid=',
              payload.data.callerId,
              '&callername=',
              payload.data.callerName,
              '&calleravatar=',
              payload.data.image,
              '&time=',
              now.toISOString()
            )
          )
        );

        notification.addEventListener('click', () => {
          window.open(
            'https://acvshrstomiddleware.z21.web.core.windows.net/' + '?' + params,
            '_blank',
            'directories=0,titlebar=0,toolbar=0,location=0,status=0,menubar=0,scrollbars=1,resizable=0,width=' +
              w +
              ',height=' +
              h +
              ', left=' +
              leftPosition +
              ', top=' +
              topPosition
          );
        });
      }
    });
  }

  const isTokenEqual = (token: string) => {
    if (localStorage.getItem('firebaseToken') == token) return true;
    localStorage.setItem('firebaseToken', token);
    return false;
  };

  const sendTokenToNotificationApi = (token: string) => {
    let userDevice = {
      DeviceToken: token,
      UserId: userId,
      DevicePlatform: 'web',
      Host: window.location.host,
      DeviceId: navigator.userAgent,
    };
    let notificationApi = new NotificationApi();
    notificationApi
      .subscribeToPushNotifications(userDevice)
      .then(() => {
        console.log('Firebase Token sent to server');
      })
      .catch((error) => {
        console.warn(`Failed to send firebase token to server: ${error}`);
      });
  };

  const sendTokenToMonolithic = (token: string) => {
    //send to AccuVFoundation api
    let parameter = {
      DeviceToken: token,
      UserId: userId,
      DevicePlatform: 'web',
      DeviceId: navigator.userAgent, // We don't really use that DeviceId property, but just put something there
    };
    let userNotificationApi = new UserNotificationApi();
    userNotificationApi
      .sendTokenToServer(parameter)
      .then(() => {
        console.log('Firebase Token sent to server');
      })
      .catch((err) => {
        console.log('failed to send firebase token to server, error:', err);
      });
  };
};
